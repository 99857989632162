html, body {
	scroll-behavior: smooth;
}

:root {
	--body-font-size: 16px;

	@include breakpoint(tablet){
		--body-font-size: 22px;
	}
}

h1, h2, h3, h4, h5, h6 {
	color: $white;
}

h6 {
	text-transform: uppercase;
	letter-spacing: 10px;	
}

p, .page__article ul li {
	font-size: var(--body-font-size);
	margin-bottom: 1em;
}

.home {
	background: url('../images/bg4-mobile.jpg'), $primary;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: scroll;

	@include breakpoint(tablet){
		background-image: url('../images/bg4-tablet.jpg');
	}

	@include breakpoint(desktop){
		background-image: url('../images/bg4.jpg');
	}
}

.hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;

	width: 100%;
	min-height: 100vh;
	padding: $global-padding*3 $global-padding/2;
	
	background:  linear-gradient(0deg, $primary 0%, rgba($primary, 0.7) 100%);
	color: $white;
	text-align: center;

	h3, h1 {
		position: relative;
		z-index: 3;
	}

	h3 {
		flex: 0 0 100%;
		font-size: rem-calc(30);
		font-weight: 900;
		font-style: italic;
		text-transform: uppercase;
		letter-spacing: rem-calc(12);
		line-height: 1.1;

		background: linear-gradient(20deg, $red 0%, $purple 80%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		word-wrap: break-word;

		@include breakpoint(tablet){
			font-size: rem-calc(70);
			margin: 0;
		}

		@include breakpoint(desktop){
			font-size: rem-calc(90);
		}
	}

	h1 {
		flex: 0 0 100%;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 6px;		
		color: $white;
		margin: rem-calc(0 0 10);
	}	

	@include breakpoint(tablet){
		padding: $global-padding*2 $global-padding;
	}
		
}

.intro {
	margin-top: rem-calc(30);	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-wrap: wrap;

	h4, h5, p {
		flex: 0 0 100%;
		max-width: rem-calc(450);
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		@include breakpoint(laptop){
			max-width: rem-calc(750);
		}
	}


	@include breakpoint(laptop){
		margin-top: rem-calc(50);
	}

	@include breakpoint(desktop){
		margin-top: rem-calc(70);
	}


}

.wrap {
	position: relative;
	z-index: 77;
	background: $primary;
	color: $white;
}


.meta {
	display: inline-block;
	margin: 0;
	font-size: rem-calc(14);
	background: linear-gradient(40deg, $red 0%, $purple 80%);
	background-size: rem-calc(200);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;	

	@include breakpoint(tablet){
		font-size: rem-calc(20);
	}

	.meta--post {
		font-size: rem-calc(20);

		@include breakpoint(tablet){
			font-size: rem-calc(22);
		}
	}
}


.button {
	font-family: 'Movement', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
	font-weight: bold;
	border: 2px solid $primary;
	text-align: center;

	&:hover {
		color: $secondary;
		background: $primary;
	}

	&:active {
		color: $secondary;
		background: darken($primary, 10%);		
	}

	&:focus {
		outline: none;
		color: $secondary;
	}
}

.button--secondary {
	color: $secondary;
	border-color: $secondary;

	&:hover {
		color: $primary;
		background: $secondary;
	}

	&:active {
		color: $primary;
		background: darken($secondary, 10%);		
	}

	&:focus {
		outline: none;
		background: darken($secondary, 15%);	
		color: $primary;
	}	
}

.button--svg {
	display: block;
	width: rem-calc(40);
	padding: 0;

	svg {
		fill: rgba($secondary, 0.85);
		width: rem-calc(40);
		height: rem-calc(40);
		transition: 0.1s ease;
	}

	&:hover svg {
		fill: $secondary;
	}

	&:active {
		background: none;
		svg { fill: darken($secondary, 5%); }
	}

	&:focus {
		background: none;
		svg { fill: darken($secondary, 8%); }
	}	
}

.button--small {
	padding: rem-calc(10 35);
}

.readmore {

	display: inline-block;
	font-family: $header-font-family;
	font-weight: 900;
	color: $white;
	border-bottom: 1px dashed $secondary;
	padding: rem-calc(0 0 4 0);

	&:hover {
		color: $white;
		border-bottom: 1px solid $secondary;
	}
}

